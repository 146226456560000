import React from "react";
import { Link } from "gatsby";
import Img from 'gatsby-image';
import { FaWhatsapp, FaChevronRight } from 'react-icons/fa'
import './template.scss';

import Slider from '../Slider/Swiper';

const MoldeProduto = ({Produto, Descricao, Imagem, lista1, lista2}) => {

    const InfoMolde = {
        altPadrao: 'Companhia da Vedação - Vedações industriais em Curitiba',
    }

    return (
        <>
            <main className="template-wrapper">
                <div className="template-redirect-wrapper">
                    <Link to="/Produtos" className="template-redirect-btn" alt="Companhia da Vedação - Curitiba">
                        Produtos
                    </Link>
                    <span>
                        <FaChevronRight/>
                    </span>
                    <p>{Produto}</p> 
                </div>
                <div className="template-produto">
                    <div className="template-image-wrapper">
                        <Img className="template-image" title={Produto} fluid={Imagem} alt={`${InfoMolde.altPadrao}`}/>
                    </div>
                    <div className="template-infos">
                        <div className="template-caixa-info">
                            <h3>{Produto}</h3>
                            <p className="template-description">{Descricao}</p>
                            <div className="template-list-wrapper">
                                <ul className="template-list">
                                    {
                                        lista1.map((item, index) => {
                                            return (
                                                <li key={index}><p>{item}</p></li>
                                            )
                                        })
                                    }
                                </ul>
                                <ul className="template-list">
                                    {
                                        lista2.map((item, index) => {
                                            return (
                                                <li key={index}><p>{item}</p></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=+5541991675557&text=Olá,+estou+entrando+em+contato+pelo+site" target="blank" alt={`${InfoMolde.altPadrao}`}>
                                <span className="template-icon"><FaWhatsapp/></span>
                                Falar com um Vendedor
                            </a>
                        </div>
                    </div>
                </div>
                <div className="template-slider">
                    <Slider/>
                </div>
            </main>
        </>
    )
}

export default MoldeProduto;