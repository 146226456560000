import React from 'react';
import { graphql } from 'gatsby';

import Cabecalho from '../../Components/header/Header';
import Head from '../../Components/seo/Seo';
import MoldeProduto from '../../Components/PageProdutos/TempleteProd';
import Footer from '../../Components/footer/Rodape';

export default function ProdutoTemplate({ data }) {

  const { markdownRemark: post } = data 

  return (
    <>
      <Head Titulo={"Companhia da Vedação - Produtos"}/>
      <Cabecalho/>
      <MoldeProduto 
        Produto={`${post.frontmatter.title}`} 
        Descricao={`${post.frontmatter.descricao}`} 
        Imagem={post.frontmatter.imagem.childImageSharp.fluid}
        lista1={post.frontmatter.details1}
        lista2={post.frontmatter.details2}
      />
      <Footer/>
    </> 
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        descricao
        imagem {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      details1
      details2
      }
    }
  }
`